<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          Chars filter
          <span class="d-block text-muted pt-2 font-size-sm"
            >Add new fields or change the types of existing ones.</span
          >
        </h3>
      </div>
      <div class="card-toolbar">
        <!--begin::Button-->
        <add_dialog />
        <reload_elastic class="ml-2" />
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :search="search"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:item.actions="{ item }">
                <a href="" @click.prevent="deleteItem(item)">Delete</a>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import {
  CHAR_FILTER_DELETE,
  CHARS_FILTER_LOAD
} from "@/core/services/store/chars_filter.module";
import add_dialog from "./add";
import reload_elastic from "../tools/reloadElastic";

export default {
  components: { add_dialog, reload_elastic },
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Term", value: "term" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.chars_filter.errors,
      message: state => state.chars_filter.message,
      hasMessage: state => state.chars_filter.hasMessage,
      items: state => state.chars_filter.chars_list,
      loading: state => state.chars_filter.loading
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: `You are want delete \`${item.term}\`. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(CHAR_FILTER_DELETE, item.id).then(() => {
            this.reloadData();
            Swal.fire(
              "Deleted!",
              "At midnight be set on search engine. For immediately click on `force reload` on right top.",
              "success"
            );
          });
        }
      });
    },
    reloadData() {
      this.$store.dispatch(CHARS_FILTER_LOAD);
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>
