<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <a
        v-on="on"
        @click="refresh"
        href="#"
        class="btn btn-primary font-weight-bolder"
      >
        New Record</a
      >
    </template>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Add char filter</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  required
                  v-model="term"
                  label="Term"
                  ref="f_term"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-btn color="darken-1" text @click="dialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import {
  CHAR_FILTER_ADDNEW,
  CHARS_FILTER_LOAD,
  REFRESH
} from "@/core/services/store/chars_filter.module";
import Swal from "sweetalert2";

export default {
  data: () => ({
    valid: true,
    dialog: false,
    term: ""
  }),
  methods: {
    refresh: function() {
      this.$store.dispatch(REFRESH);
    },
    save() {
      if (this.term.length !== 1) {
        Swal.fire("Only one char please set", "", "error");
        return false;
      }

      this.$store
        .dispatch(CHAR_FILTER_ADDNEW, {
          term: this.term
        })
        .then(() => {
          this.dialog = false;
          this.$store.dispatch(REFRESH);
          this.$store.dispatch(CHARS_FILTER_LOAD);
          Swal.fire(
            "Added!",
            "At midnight be set on search engine. For immediately click on `force reload` on right top.",
            "success"
          );
        });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.synonyms.errors,
      message: state => state.synonyms.message,
      hasMessage: state => state.synonyms.hasMessage
    })
  },
  beforeCreate() {
    this.term = "";
    this.$store.dispatch(REFRESH);
  }
};
</script>
